 <template>
    <!-- 样式组件 -->
    <div :class="'liefeng-info-view'" :style="'height:' + (data.height || height) * proportion + 'px;width:' + (data.width || width) * proportion + 'px'" v-if="!isParamComp">
        <img src="/images/search.png" />
    </div>
    <!-- 参数设置组件 -->
    <div class="liefeng-info-params" v-else>
        <Form :label-width="100" ref="infoForm">
            <FormItem label="宽度">
                <Input type="Number" v-model.number="data.width" placeholder="Banner宽度，默认：500" @on-blur="handleData"></Input>
            </FormItem>
            <FormItem label="高度">
                <Input type="Number" v-model.number="data.height" placeholder="Banner高度，默认：320" @on-blur="handleData"></Input>
            </FormItem>
            <FormItem label="左边距">
                <Input type="Number" v-model.trim="data.marginLeft" @on-blur="handleData"></Input>
            </FormItem>
            <FormItem label="右边距">
                <Input type="Number" v-model.trim="data.marginRight" @on-blur="handleData"></Input>
            </FormItem>
            <FormItem label="上边距">
                <Input type="Number" v-model.trim="data.marginTop" @on-blur="handleData"></Input>
            </FormItem>
            <FormItem label="下边距">
                <Input type="Number" v-model.trim="data.marginBottom" @on-blur="handleData"></Input>
            </FormItem>
            <FormItem label="搜索栏名称">
                <Input v-model.trim="data.name"></Input>
            </FormItem>
            <FormItem label="固定至顶部">
                <RadioGroup v-model="data.isFixed">
                    <Radio :label="true">是</Radio>
                    <Radio :label="false">否</Radio>
                </RadioGroup>
            </FormItem>
            <FormItem label="输入类型">
                <RadioGroup v-model="data.inputType">
                    <Radio label="text">文字</Radio>
                    <Radio label="number">数字</Radio>
                    <Radio label="idcard">身份证</Radio>
                </RadioGroup>
            </FormItem>
            <FormItem label="右侧按钮显示">
                <RadioGroup v-model="data.showActionButton">
                    <Radio :label="true">一直显示</Radio>
                    <Radio :label="false">聚焦显示</Radio>
                </RadioGroup>
            </FormItem>
            <FormItem label="右侧按钮文字">
                <Input v-model.trim="data.actionName" placeholder="默认：搜索"></Input>
            </FormItem>
            <FormItem label="右侧按钮背景">
                <ColorPicker v-model="data.backgroundColor" recommend @on-active-change="colorChange" />
            </FormItem>
            <FormItem label="占位符文字">
                <Input v-model.trim="data.placeholder" placeholder=""></Input>
            </FormItem>
            <FormItem label="是否跳转页面">
                <RadioGroup v-model="data.redirect">
                    <Radio :label="false">否，需与内容组件绑定</Radio>
                    <Radio :label="true">是，绑定的内容组件无效</Radio>
                </RadioGroup>
            </FormItem>
            <FormItem label="跳转页面" v-if="data.redirect">
                <Input v-model.trim="data.url" placeholder="跳转的面页地址，包括固定的参数"></Input>
            </FormItem>
            <FormItem label="查询字段名" v-if="data.redirect">
                <Input v-model.trim="data.keyword" placeholder=""></Input>
            </FormItem>
        </Form>
    </div>
</template>

<script>
/**
 * import Search from "./components/Search";
 * 标签页组件
 */
export default {
    components: {},
    props: {
        isParamComp: { type: Boolean, default: false }, //true为参数设置组件,false为样式组件
        height: { type: Number, default: 500 }, //高度，单位：px
        width: { type: Number, default: 500 }, //高度，单位：px
        data: {
            //组件数据，每个组件需要具备
            type: Object,
            default: () => {
                return {}
            },
        },
        target: {
            //组件对象，每个组件需要具备
            type: Object,
            default: () => {
                return {}
            },
        },
        proportion: {
            type: Number,
            default: () => {
                return 0
            },
        },
    },
    data() {
        return {
            initData: {
                //初始化用的数据，每个组件需要具备
                //初始化用的数据，每个组件需要具备
                height: 500,
                width: 500,
                name: "",
                type: "Search",
                showActionButton: true,
                placeholder: "",
                actionName: "搜索",
                inputType: "text",
                backgroundColor: "#00BCD4",
                isFixed: false,
                redirect: false,
                url: "",
                keyword: "",
                marginLeft: "0",
                marginRight: "0",
                marginTop: "0",
                marginBottom: "0",
            },
        }
    },
    watch: {
        data: {
            handler(item, oldItem) {
                if (!item.name) item.name = this.$core.createID("SB")
                if (item.isFixed == null) item.isFixed = false
            },
            immediate: true,
        },
    },
    mounted() {},
    created() {
        this.handleData()
    },
    methods: {
        colorChange(color) {
            this.data.backgroundColor = color
        },
        handleData() {
            this.$(this.target).css("height", (this.data.height || 500) * this.proportion + "px")
            this.$(this.target).css("width", (this.data.width || 500) * this.proportion + "px")
            this.$(this.target).css("marginLeft", Number(this.data.marginLeft) * this.proportion + "px")
            this.$(this.target).css("marginRight", Number(this.data.marginRight) * this.proportion + "px")
            this.$(this.target).css("marginTop", Number(this.data.marginTop) * this.proportion + "px")
            this.$(this.target).css("marginBottom", Number(this.data.marginBottom) * this.proportion + "px")
        },
    },
}
</script>
<style lang="less">
.liefeng-info-view {
    width: 100%;
    margin: 5px 0;
    overflow: hidden;
    background-color: #eee;
    img {
        width: 100%;
    }
}
</style>